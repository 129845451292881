import { ImageBitmapLoader, LinearMipmapLinearFilter, SRGBColorSpace, Texture } from 'three';

export const loadBitmapTexture = (
    url,
    { flipY = false, minFilter = LinearMipmapLinearFilter, innerCard, direction, side } = {}
) => {
    const loader = new ImageBitmapLoader();

    if (!flipY) loader.setOptions({ imageOrientation: 'flipY' });

    return new Promise((resolve, reject) => {
        loader.load(
            url,
            (imageBitmap) => {
                const texture = new Texture(imageBitmap);
                texture.colorSpace = SRGBColorSpace;
                texture.flipY = false;
                texture.minFilter = minFilter;
                texture.needsUpdate = true;

                texture.center.set(0.5, 0.5);

                if (innerCard) {
                    const isLandscapeDimension = imageBitmap.width > imageBitmap.height;

                    //The above condition should never be true. We guard against obvious data issues.
                    if (isLandscapeDimension && ['top', 'bottom'].includes(direction)) {
                        const sign = side === 'front' ? -1 : 1;
                        texture.rotation = (Math.PI / 2) * sign;
                    }
                    switch (direction) {
                        case 'top':
                            break;
                        case 'right':
                            texture.rotation = Math.PI / 2; // 90 degrees clockwise
                            break;
                        case 'bottom':
                            texture.rotation = Math.PI; // 180 degrees
                            break;
                        case 'left':
                            texture.rotation = -Math.PI / 2; // 90 degrees counterclockwise
                            break;
                    }
                }

                resolve(texture);
            },
            undefined,
            (err) => {
                // reject(err);

                console.warn(err);

                resolve(new Texture());
            }
        );
    });
};
